<ng-container *ngIf="link === undefined || link.length === 0; else useLink">
    <div class="side-nav-item" [ngClass]="{'closed': (navService.sideNavCollapsed$ | async), 'active': highlighted}">
        <ng-container [ngTemplateOutlet]="inner"></ng-container>
    </div>
</ng-container>

<ng-template #useLink>
    <ng-container *ngIf="external; else internal">
        <a class="side-nav-item" [href]="link" [ngClass]="{'closed': (navService.sideNavCollapsed$ | async), 'active': highlighted}" rel="noopener noreferrer" target="_blank">
            <ng-container [ngTemplateOutlet]="inner"></ng-container>
        </a>
    </ng-container>
    <ng-template #internal>
        <a class="side-nav-item" href="javascript:void(0);" [ngClass]="{'closed': (navService.sideNavCollapsed$ | async), 'active': highlighted}" [routerLink]="link">
            <ng-container [ngTemplateOutlet]="inner"></ng-container>
        </a>
    </ng-template>
    
</ng-template>


<ng-template #inner>
    <div class="active-highlight"></div>
    <span class="phone-hidden" title="{{title}}">
        <div>
            <ng-container *ngIf="imageUrl !== null && imageUrl !== ''; else iconImg">
                <img [src]="imageUrl" alt="icon" class="side-nav-img">
            </ng-container>
            <ng-template #iconImg><i class="fa {{icon}}" aria-hidden="true"></i></ng-template>
        </div>
    </span>
    <span class="side-nav-text">
        <div>
            {{title}}
        </div>
    </span>
    <span class="card-actions">
        <ng-content select="[actions]"></ng-content>
    </span>
</ng-template>